import React from 'react';

import PartnerLaravel from '../assets/images/partner-img/laravel.png'
import PartnerHoverLaravel from '../assets/images/partner-img/laravel-hover.png'
import PartnerReact from '../assets/images/partner-img/react.png'
import PartnerHoverReact from '../assets/images/partner-img/react-hover.png'
import PartnerVue from '../assets/images/partner-img/vue.png'
import PartnerHoverVue from '../assets/images/partner-img/vue-hover.png'
import PartnerWordpress from '../assets/images/partner-img/wordpress.png'
import PartnerHoverWordpress from '../assets/images/partner-img/wordpress-hover.png'
import PartnerImg5 from '../assets/images/partner-img/partner-5.png'
import PartnerHoverImg5 from '../assets/images/partner-img/partner-hover5.png'
import PartnerImg6 from '../assets/images/partner-img/partner-6.png'
import PartnerHoverImg6 from '../assets/images/partner-img/partner-hover6.png'
import PartnerImg7 from '../assets/images/partner-img/partner-7.png'
import PartnerHoverImg7 from '../assets/images/partner-img/partner-hover7.png'

import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel3'));

const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 5000,
    responsive: {
        0:{
            items:2,
        },
        576:{
            items:4,
        },
        768:{
            items:4,
        },
        1024:{
            items:5,
        },
        1200:{
            items:6,
        }
    }
}

const Partner = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="ml-partner-area mt-minus-top ptb-80 pb-0">
            <div className="container">
                {display ? <OwlCarousel
                    className="partner-slides owl-carousel owl-theme"
                    {...options}
                >
                    <div className="single-ml-partner">
                        <a href="https://laravel.com" target="_blank" rel="noreferrer">
                            <img src={PartnerLaravel} alt="laravel image" />
                            <img src={PartnerHoverLaravel} alt="laravel image" />
                        </a>
                    </div>

                    <div className="single-ml-partner">
                        <a href="https://reactjs.org" target="_blank" rel="noreferrer">
                            <img src={PartnerReact} alt="react image" />
                            <img src={PartnerHoverReact} alt="react image" />
                        </a>
                    </div>

                    <div className="single-ml-partner">
                        <a href="https://vuejs.org" target="_blank" rel="noreferrer">
                            <img src={PartnerVue} alt="vue image" />
                            <img src={PartnerHoverVue} alt="vue image" />
                        </a>
                    </div>

                    <div className="single-ml-partner">
                        <a href="https://wordpress.org" target="_blank" rel="noreferrer">
                            <img src={PartnerWordpress} alt="image" />
                            <img src={PartnerHoverWordpress} alt="image" />
                        </a>
                    </div>

                    <div className="single-ml-partner">
                        <a href="https://github.com" target="_blank" rel="noreferrer">
                            <img src={PartnerImg5} alt="image" />
                            <img src={PartnerHoverImg5} alt="image" />
                        </a>
                    </div>

                    <div className="single-ml-partner">
                        <a href="https://nodejs.org" target="_blank" rel="noreferrer">
                            <img src={PartnerImg6} alt="image" />
                            <img src={PartnerHoverImg6} alt="image" />
                        </a>
                    </div>

                    <div className="single-ml-partner">
                        <a href="https://rubyonrails.org" target="_blank" rel="noreferrer">
                            <img src={PartnerImg7} alt="image" />
                            <img src={PartnerHoverImg7} alt="image" />
                        </a>
                    </div>
                </OwlCarousel> : ''}
            </div>
        </div>
    );
}

export default Partner;
