import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';

import Icon4 from "../assets/images/icon4.png";
import Icon5 from "../assets/images/icon5.png";
import Icon6 from "../assets/images/icon6.png";
import Icon7 from "../assets/images/icon7.png";

// Shape Images
import Shape1 from "../assets/images/shape1.png";
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";

const WhatWeOffer = () => {
    return (
        <div className="solutions-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">Our Key Values</span>
                    <h2>Grow your business and win the competition with us</h2>
                    <div className="bar"></div>
                    <p>We keep up with the latest trends to create better strategies, deliver unique values, and gain more brand awareness</p>
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={Icon4} alt="image" />
                            </div>
                            <h3>
                                <span>Latest Technology</span>
                            </h3>
                            <p>We use the latest software technologies to give you high quality digital applications and service no matter your industry.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={Icon5} alt="image" />
                            </div>
                            <h3>
                                <span>
                                    Creative Design 
                                </span>
                            </h3>
                            <p>Design is one of the essential aspects in online presence, so we ensure you get perfect and creative design.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={Icon6} alt="image" />
                            </div>
                            <h3>
                                <span to="/service-details">
                                Long-term Cooperation
                                </span>
                            </h3>
                            <p>We believe that our clients satisfaction matters, so we always give the best service to maintain good relationships.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <img src={Icon7} alt="image" />
                            </div>
                            <h3>
                                <span to="/service-details">
                                    Free Consultation
                                </span>
                            </h3>
                            <p>We are always ready to assist you. Feel free to talk anytime and anywhere with no consultation fee. We're here to help.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </div>
    );
}

export default WhatWeOffer;
