import React from "react"
import { Link } from "gatsby"

// Service Images
import ServiceImage1 from "../assets/images/services-all-image/service-image1.png"
import ServiceImage2 from "../assets/images/services-all-image/service-image2.png"
import ServiceImage3 from "../assets/images/services-all-image/service-image3.png"
import ServiceImage4 from "../assets/images/services-all-image/service-image4.png"
import ServiceImage5 from "../assets/images/services-all-image/service-image5.png"
import ServiceImage6 from "../assets/images/services-all-image/service-image6.png"

// Shape Images
import Shape1 from "../assets/images/shape1.png"
import Shape2 from "../assets/images/shape2.svg"
import Shape3 from "../assets/images/shape3.svg"
import Shape4 from "../assets/images/shape4.svg"

const OurServices = () => {
  return (
    <div id="services-area" className="ml-services-area ptb-80">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">How We Work</span>
          <h2>Our Services</h2>
          <div className="bar"></div>
          <p>
            Our services are designed to create a high technology system that
            will elevate your business.
          </p>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="single-ml-services-box">
              <div className="image">
                <img src={ServiceImage1} alt="image" />
              </div>
              <h3>
                <span className="services-title">Website Development</span>
              </h3>
              <p>
                With our expertise in building websites, Vexatech professionally
                develop and redesign the website for company profile, management
                system, and other needs.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-ml-services-box">
              <div className="image">
                <img src={ServiceImage2} alt="image" />
              </div>
              <h3>
                <span className="service-title">Application Development</span>
              </h3>
              <p>
                Let’s collaborate with us to bring innovation and development in
                mobile applications both for Android and iOS systems to seize
                the market.{" "}
              </p>
              <br></br>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-ml-services-box">
              <div className="image">
                <img src={ServiceImage3} alt="image" />
              </div>
              <h3>
                <span className="service-title">Server Management</span>
              </h3>
              <p>
                With vexaTech, we will manage your server effectively and
                efficiently. We also provide VPS to rent only for $12 (4 vCpu
                Cores & 8GB RAM)
              </p>
              <br></br>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-ml-services-box">
              <div className="image">
                <img src={ServiceImage4} alt="image" />
              </div>
              <h3>
                <Link to="/service-details">SEO & Content Creation</Link>
              </h3>
              <p>
                SEO and Content are the essential parts of every digital
                marketing program. A lot of businesses are satisfied with our
                SEO and content services.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-ml-services-box">
              <div className="image">
                <img src={ServiceImage5} alt="image" />
              </div>
              <h3>
                <Link to="/service-details">Digital Marketing</Link>
              </h3>
              <p>
                As a full-service digital marketing agency, we’re proud to help
                our clients in every area of digital marketing such as digital
                ads and analytics.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-ml-services-box">
              <div className="image">
                <img className="w200px" src={ServiceImage6} alt="image" />
              </div>
              <h3>
                <Link to="/service-details">Graphic Design</Link>
              </h3>
              <p>
                If you're looking for a creative new logo, banner design or
                stunning flyers, our team at VexaTech can make it happen!
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Shape Images */}
      <div className="shape1">
        <img src={Shape1} alt="shape" />
      </div>
      <div className="shape2 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
      <div className="shape3">
        <img src={Shape3} alt="shape" />
      </div>
      <div className="shape4">
        <img src={Shape4} alt="shape" />
      </div>
      <div className="shape7">
        <img src={Shape4} alt="shape" />
      </div>
      <div className="shape8 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
    </div>
  )
}

export default OurServices
