import React from 'react';
import * as Icon from 'react-feather';

// Client Images
import ClientDeko from '../assets/images/client-image/deko.png'
import ClientEgi from '../assets/images/client-image/egi.png'
import ClientDishub from '../assets/images/client-image/dishubb.png'
import ClientMsbu from '../assets/images/client-image/msbu.png'
import ClientUajy from '../assets/images/client-image/ujay.png'
import ClientKaye from '../assets/images/client-image/kaye.png'
import ClientJogjaLodge from '../assets/images/client-image/maria.png'
import ClientSinergi from '../assets/images/client-image/sinergi.png'

// Shape Images
import Shape1 from "../assets/images/shape1.png";
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";

import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: false,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 5000,
    items: 1,
    animateOut: 'fadeOut',
}

const FeedbackStyleFour = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div id="feedbacks-area" className="ml-feedback-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <h2>What they say about us</h2>
                    <div className="bar"></div>
                    <p>We helped a lot of businesses to thrive on digital services.</p>
                </div>

                {display ? <OwlCarousel
                    className="ml-feedback-slides owl-carousel owl-theme"
                    {...options}
                >

                    <div className="single-ml-feedback-item">
                        <div className="client-info">
                            <img src={ClientDishub} alt="image" />
                            <h3>Budi</h3>
                            <span>Kasi Mamrek</span>
                            <span>Dishub Kab. Madiun</span>
                        </div>
                        <p>VexaTech has been a trusted partner in building our system and succeeded in creating a platform that is not only visually appealing but also functions well</p>

                        <div className="rating">
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                        </div>
                    </div>

                    <div className="single-ml-feedback-item">
                        <div className="client-info">
                            <img src={ClientMsbu} alt="image" />
                            <h3>Rifki R.</h3>
                            <span>Founder of MSBU</span>
                        </div>
                        <p>As a developer, VexaTech is very professional and results-oriented. We have had several projects with him, starting from building custom websites, integrating payment APIs and finally the WordPress blog project, all completed and never had any post go-live problems. Even if there are bugs, he is still responsive to help us.</p>

                        <div className="rating">
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                        </div>
                    </div>

                    <div className="single-ml-feedback-item">
                        <div className="client-info">
                            <img src={ClientUajy} alt="image" />
                            <h3>Vincentius A.</h3>
                            <span>Kasubbag</span>
                            <span>Pengajaran KAA UAJY</span>
                        </div>
                        <p>VexaTech is professional in project work. Time targets and needs can be accommodated very well. The care of the finished product is also exceptionally good. Thank you VexaTech!</p>

                        <div className="rating">
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                        </div>
                    </div>

                    <div className="single-ml-feedback-item">
                        <div className="client-info">
                            <img src={ClientKaye} alt="image" />
                            <h3>kaye</h3>
                            <span>Founder Kayestore</span>
                        </div>
                        <p>VexaTech as the developer on my website, namely kayestore.com, is very, very professional. he has helped a lot with the development of my website. from front end to back end. all projects carried out are completed.</p>

                        <div className="rating">
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                        </div>
                    </div>

                    <div className="single-ml-feedback-item">
                        <div className="client-info">
                            <img src={ClientJogjaLodge} alt="image" />
                            <h3>Maria M.</h3>
                            <span>Jogja Lodge</span>
                        </div>
                        <p>Thank you Vexa Tech for helping us develop our business through websites and other digital networks. We get the best team service from Vexa Tech. Thank You.</p>

                        <div className="rating">
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                        </div>
                    </div>
                    <div className="single-ml-feedback-item">
                        <div className="client-info">
                            <img src={ClientSinergi} alt="image" />
                            <h3>Warsito</h3>
                            <span>Kepala Divisi</span>
                        </div>
                        <p>I am very satisfied with my experience working with Vexatech Digital Agency & Software House. They not only provide outstanding work results, but are also consistent in meeting set deadlines. Their team is very communicative and responsive, making the collaboration process smooth and efficient. Thank you for the dedication and professionalism shown by the Vexatech team!</p>

                        <div className="rating">
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                        </div>
                    </div>
                     <div className="single-ml-feedback-item">
                        <div className="client-info">
                            <img src={ClientDeko} alt="image" />
                            <h3>Dian Eko</h3>
                            <span>Founder NewsBMB</span>
                        </div>
                        <p>VEXATECH is very helpful in developing the security and navigation of our website, so that newsbmb.com can appear even better. Thank you Vexatech</p>

                        <div className="rating">
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                        </div>
                    </div>

                    <div className="single-ml-feedback-item">
                        <div className="client-info">
                            <img src={ClientEgi} alt="image" />
                            <h3>Egi Endeska</h3>
                            <span>CEO Persona IT</span>
                        </div>
                        <p>Collaborating with VexaTech in building the dparagon project is very helpful</p>

                        <div className="rating">
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                        </div>
                    </div>    
                </OwlCarousel> : ''}
            </div>

            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </div>
    );
}

export default FeedbackStyleFour;
