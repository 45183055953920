import React from 'react';
import { Link } from "gatsby";

import AboutImg from "../assets/images/about4.png";

// Shape Images
import Shape1 from "../assets/images/shape1.png";
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";

const AboutUsContent = () => {
    return (
        <div id="about-area" className="about-area pt-0 ptb-80">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="ml-about-img">
                            <img className="about-img" src={AboutImg} alt="image" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="ml-about-content">
                            <span className="sub-title">About Us</span>
                            <h2>Engage More Audience with Our Digital Services.</h2>
                            <div className="bar"></div>
                            <p>Vexatech is a digital agency that helps businesses grow their digital platforms such as website and app development, digital marketing, server management and graphic design. </p>
                            <p>We are always committed to provide optimal and best solutions for every client with competitive prices. If you want to increase your digital value with experienced outsourcing company, you are in the right place!</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </div>
    );
}

export default AboutUsContent;
