import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Navbar from "../components/Navbar"
import MainBanner from "../components/MainBanner"
import Partner from "../components/Partner"
import WhatWeOffer from "../components/WhatWeOffer"
import AboutUsContent from "../components/AboutUsContent"
import OurServices from "../components/OurServices"
import Projects from "../components/Projects"
import FeedbackStyleFour from "../components/FeedbackStyleFour"
import Footer from "../components/Footer"

const IndexPage = () => (
  <Layout>
    <SEO title="VexaTech" />
    <Navbar />
    <MainBanner />
    <Partner />
    <WhatWeOffer />
    <AboutUsContent />
    <OurServices />
    <Projects />
    <FeedbackStyleFour />
    <Footer />
  </Layout>
)

export default IndexPage
