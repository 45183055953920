import React from "react"
import { Link } from "gatsby"
import * as Icon from "react-feather"

import Logo from "../assets/images/logo.png"
import MapImg from "../assets/images/map.png"
import Shape1 from "../assets/images/shape1.png"
import Shape2 from "../assets/images/shape2.svg"

const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <footer className="footer-area bg-f7fafd">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-6">
            <div className="single-footer-widget">
              <div className="logo">
                <Link to="/">
                  <img src={Logo} className="w-80" alt="logo" />
                </Link>
              </div>
              <p>
                Businesses today need to upscale their digital assets and
                service without complicated and expensive frameworks. We are
                creating VexaTech to solve the problems, by providing the best
                digital services, recreating your value, and increasing
                efficiency of your digital platforms.
              </p>
            </div>
          </div>

          <div className="col-lg-2 col-sm-6">
            <div className="single-footer-widget pl-5">
              <h3>Company</h3>
              <ul className="list">
                <li>
                  <Link to="/#about-area">About Us</Link>
                </li>
                <li>
                  <Link to="/#solutions-area">Solutions</Link>
                </li>
                <li>
                  <Link to="/#ml-services-area">Services</Link>
                </li>
                <li>
                  <Link to="/#ml-projects-area">Portfolio</Link>
                </li>
                <li>
                  <Link to="/#ml-feedbacks-area">Feedback</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <h3>Support</h3>
              <ul className="list">
                <li>
                  <Link to="/faq">FAQ's</Link>
                </li>
                <li>
                  <Link to="/404">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/404">Terms & Condition</Link>
                </li>
                <li>
                  <Link to="/team">Team</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="single-footer-widget">
              <h3>Address</h3>

              <ul className="footer-contact-info">
                <li>
                  <Icon.MapPin />
                  Condongcatur, Depok, Sleman
                  <br /> Daerah Istimewa Yogyakarta
                </li>
                <li>
                  <Icon.Mail />
                  Email:{" "}
                  <Link to="mailto:admin@vexatechno.com">
                    admin@vexatechno.com
                  </Link>
                </li>
                <li>
                  <Icon.PhoneCall />
                  Whatsapp:{" "}
                  <Link to="https://api.whatsapp.com/send?phone=6281228170370">
                    +(62) 812 2817 0370
                  </Link>
                </li>
              </ul>

              <ul className="social-links">
                <li>
                  <a
                    href="#"
                    className="facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon.Facebook />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="twitter"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon.Twitter />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon.Instagram />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="linkedin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon.Linkedin />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="copyright-area">
              <p>
                Copyright &copy; {currentYear}. All rights reserved by{" "}
                <a href="https://vexatechno.com/">VexaTech</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <img src={MapImg} className="map" alt="map" />

      {/* Shape Images */}
      <div className="shape1">
        <img src={Shape1} alt="shape" />
      </div>
      <div className="shape8 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
    </footer>
  )
}

export default Footer
