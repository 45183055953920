import React from "react"
import { Link } from "gatsby"

import ProjectsImg1 from "../assets/images/projects-img1.jpg"
import ProjectsImg2 from "../assets/images/projects-img2.jpg"
import ProjectsImg3 from "../assets/images/projects-img3.jpg"
import ProjectsImg4 from "../assets/images/projects-img4.jpg"
import ProjectsImg5 from "../assets/images/projects-img5.jpg"

import ImgDparagon from "../assets/images/projects/dparagon.png"
import ImgDjurkam from "../assets/images/projects/djurkam.png"
import ImgDjurvoc from "../assets/images/projects/djurvoc.png"
import ImgVexagame from "../assets/images/projects/vexagame.png"
import ImgKomisiPse from "../assets/images/projects/komisipse.png"
import ImgSeucapkopi from "../assets/images/projects/seucapkopi.png"
import ImgPantaicahaya from "../assets/images/projects/pantaicahaya.png"
import ImgBluesummer from "../assets/images/projects/bluesummer.png"
import ImgNewsbmb from "../assets/images/projects/newsbmb.png"
import ImgRestudunia from "../assets/images/projects/restudunia.png"
import ImgNurhamka from "../assets/images/projects/nurhamka.png"
import ImgSmkn1mejayan from "../assets/images/projects/smkn1mejayan.png"
import ImgVstore from "../assets/images/projects/vstore.png"
import ImgJogjaLodge from "../assets/images/projects/jogjalodge.png"
import ImgAtmaJaya from "../assets/images/projects/atmajaya.png"
import ImgMsbu from "../assets/images/projects/msbu.png"
import ImgVexaWeb from "../assets/images/projects/vexaagen-web.png"
import ImgVexaApp from "../assets/images/projects/vexaagen-app.png"
import ImgVexaMobile from "../assets/images/projects/vexaagen-mobile-app.png"
import ImgVexaBlog from "../assets/images/projects/vexagame-blog.png"

// Shape Images
import Shape1 from "../assets/images/shape1.png"
import Shape2 from "../assets/images/shape2.svg"
import Shape3 from "../assets/images/shape3.svg"
import Shape4 from "../assets/images/shape4.svg"

import Loadable from "@loadable/component"
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
  loop: true,
  nav: false,
  dots: true,
  autoplay: true,
  smartSpeed: 1000,
  margin: 30,
  autoplayTimeout: 5000,
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 2,
    },
    768: {
      items: 2,
    },
    1024: {
      items: 3,
    },
    1200: {
      items: 4,
    },
  },
}

const Projects = () => {
  const [display, setDisplay] = React.useState(false)

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <div id="projects-area" className="ml-projects-area pt-5 ptb-80">
      <div className="container">
        <div className="section-title st-fs-28">
          <h2>Proud Projects From Us asdsada</h2>
          <div className="bar"></div>
          <p>
            We make our clients digital transformation through many projects and
            portfolios.
          </p>
        </div>
      </div>

      <div className="container-fluid">
        {display ? (
          <OwlCarousel
            className="ml-projects-slides owl-carousel owl-theme"
            {...options}
          >
            <div className="single-ml-projects-box">
              <img src={ImgJogjaLodge} alt="jogja lodge project" />
              <div className="plus-icon">
                <Link to="https://jogjalodge.com/" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgAtmaJaya} alt="atma jaya project" />
              <div className="plus-icon">
                <Link to="https://kaa.uajy.ac.id/" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgMsbu} alt="msbu konsultan project" />
              <div className="plus-icon">
                <Link to="https://msbu.co.id/id" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgVstore} alt="vstore project" />
              <div className="plus-icon">
                <Link to="https://vstore-ten.vercel.app/" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgDparagon} alt="dparagon project" />
              <div className="plus-icon">
                <Link to="https://dparagon.com" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgDjurkam} alt="djurkam project" />
              <div className="plus-icon">
                <Link to="https://djuragankamar.com" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgDjurvoc} alt="djurvoc project" />
              <div className="plus-icon">
                <Link to="https://djuraganvoucher.com" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgVexagame} alt="vexagame project" />
              <div className="plus-icon">
                <Link to="https://vexagame.com" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgKomisiPse} alt="komisi pse project" />
              <div className="plus-icon">
                <Link to="https://komisipseapp.com" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgSeucapkopi} alt="seucapkopi project" />
              <div className="plus-icon">
                <Link to="https://seucapkopi.id" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgPantaicahaya} alt="pantaicahaya project" />
              <div className="plus-icon">
                <Link to="https://pantaicahaya.co.id" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgBluesummer} alt="bluesummer project" />
              <div className="plus-icon">
                <Link to="https://blusummer.id" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgNewsbmb} alt="newsbmb project" />
              <div className="plus-icon">
                <Link to="https://newsbmb.com" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgRestudunia} alt="restudunia project" />
              <div className="plus-icon">
                <Link to="#" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgNurhamka} alt="nurhamka project" />
              <div className="plus-icon">
                <Link to="https://nurhamka.com" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgSmkn1mejayan} alt="smkn 1 mejayan project" />
              <div className="plus-icon">
                <Link to="https://smkn1mejayan.sch.id" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgVexaBlog} alt="vexagame projek" />
              <div className="plus-icon">
                <Link to="vexagame.com" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgVexaApp} alt="vexa agen app project" />
              <div className="plus-icon">
                <Link to="https://app.vexaagen.com/" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgVexaMobile} alt="vexa agen mobile project" />
              <div className="plus-icon">
                <Link
                  to="https://play.google.com/store/apps/details?id=com.vexa.agen"
                  target="_blank"
                >
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="single-ml-projects-box">
              <img src={ImgVexaWeb} alt="vexa agen web project" />
              <div className="plus-icon">
                <Link to="https://vexaagen.com/" target="_blank">
                  <span></span>
                </Link>
              </div>
            </div>
          </OwlCarousel>
        ) : (
          ""
        )}
      </div>

      {/* Shape Images */}
      <div className="shape1">
        <img src={Shape1} alt="shape" />
      </div>
      <div className="shape2 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
      <div className="shape3">
        <img src={Shape3} alt="shape" />
      </div>
      <div className="shape4">
        <img src={Shape4} alt="shape" />
      </div>
      <div className="shape7">
        <img src={Shape4} alt="shape" />
      </div>
      <div className="shape8 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
    </div>
  )
}

export default Projects
